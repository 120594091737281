import React from 'react';
import { IKrannichCustomLoaderViewProps } from './krannich-custom-loader';
import { Waiting } from '@msdyn365-commerce-modules/utilities';

const CustomLoaderView: React.FC<IKrannichCustomLoaderViewProps> = () => {
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        // Function to handle window load event
        const handleOnLoad = () => setLoading(false);

        // Check if document is already loaded
        if (document.readyState === 'complete') {
            setLoading(false);
        } else {
            // Add event listener for window load
            window.addEventListener('load', handleOnLoad);
        }

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('load', handleOnLoad);
        };
    }, []);

    // Render loading indicator if loading, otherwise render content
    return (
        <div className='loader'>
            {loading ? (
                <div id='load'>
                    <Waiting />
                </div>
            ) : (
                <div id='renderPage'></div>
            )}
        </div>
    );
};

export default CustomLoaderView;
