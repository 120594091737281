import React from 'react';
export const PromotionBadges: React.FC<any> = ({ promotionalBadge, context }) => {
    const { enableBadges, badges } = context?.actionContext?.requestContext?.app.config;
    const badgeUI = badges?.find((badge: { label: string }) => badge?.label?.trim() === promotionalBadge?.trim());
    const enabledForGuest = badgeUI?.enableForGuest || false;
    if (
        enableBadges &&
        (enabledForGuest || context?.actionContext?.requestContext?.user?.isAuthenticated) &&
        promotionalBadge?.length !== 0
    ) {
        return (
            <div
                className='badge'
                title={promotionalBadge}
                key={promotionalBadge}
                style={{ background: badgeUI?.bgColor || '', color: badgeUI?.fontColor || '' }}
            >
                {promotionalBadge}
            </div>
        );
    }
    return null;
};
