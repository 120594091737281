import React from 'react';
import { IKRV_ATPLeadTimePropertiesDate } from '../actions/customProxy/ATP-update/DataServiceEntities.g';
import { dateFormatByLocale } from './helper';

interface ITrafficLight {
    lightColor?: string;
    isVisible?: string;
}

interface IKRV_ATPLeadTimePropertiesDateFormat extends IKRV_ATPLeadTimePropertiesDate {
    locale?: string;
    currency?: string;
}

export const TrafficLights: React.FC<ITrafficLight> = ({ lightColor, isVisible }) => (
    <div className='traffic-light-container'>
        <div className={isVisible === 'No' ? '_red-light' : lightColor ? `_${lightColor.toLowerCase().trim()}-light` : ' _grey-light'} />
    </div>
);

export const ATPMessage: React.FC<IKRV_ATPLeadTimePropertiesDateFormat> = props => {
    /* VSI CUSTOMIZATION | DATE FORMAT | START */
    let PurchaseLeadTimeDate = props.PurchaseLeadTimeDate;
    if (props.currency && props.locale && PurchaseLeadTimeDate) {
        PurchaseLeadTimeDate = dateFormatByLocale(props.locale, props.currency, PurchaseLeadTimeDate);
    }
    let PODate = props.PODate;
    if (props.currency && props.locale && PODate) {
        PODate = dateFormatByLocale(props.locale, props.currency, PODate);
    }
    /* VSI CUSTOMIZATION | DATE FORMAT | END */
    let atpMessage = '';
    if (props.IsVisible === 'No') {
        atpMessage = 'no further supplies';
    } else if (props.IsDiscontinuedProduct === 'Yes') {
        atpMessage = props?.Message!.toLowerCase();
    } else if (PurchaseLeadTimeDate !== '' && props.Quantity === 0 && props.POQuantity === 0) {
        atpMessage = `${props.Quantity} in stock, more from ${PurchaseLeadTimeDate}`;
    } else if (PurchaseLeadTimeDate === '' && props.Quantity === 0 && props.POQuantity > 0) {
        atpMessage = `${props.Quantity} in stock, more from ${PODate}`;
    } else if (PurchaseLeadTimeDate !== '' && props.Quantity > 0 && props.POQuantity === 0) {
        atpMessage = `${props.Quantity} in stock, more from ${PurchaseLeadTimeDate}`;
    } else if (PurchaseLeadTimeDate === '' && props.Quantity > 0 && props.POQuantity > 0) {
        atpMessage = `${props.Quantity} in stock, more from ${PODate}`;
    } else if (PurchaseLeadTimeDate === '' && props.Quantity >= 0 && props.POQuantity === 0) {
        atpMessage = props?.Message ? `${props.Quantity} in stock, ${props?.Message.toLowerCase()}` : `${props.Quantity} in stock`;
    }
    if (atpMessage !== '') {
        return (
            <div className='_message-container'>
                <h4 className='_traffic-light-quantity'>{atpMessage}</h4>
            </div>
        );
    }
    return (
        <div className='_message-container'>
            <h4 className='_traffic-light-quantity'>
                <p className='ms-buybox__minmax_label_text'>
                    <span className='sr-only'>Information</span>
                    <i className='msi-InfoCircle' aria-hidden='true'></i>
                    Error while fetching the data!!!
                </p>
            </h4>
        </div>
    );
};
